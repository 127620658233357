import {  Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  Event as NavigationEvent,
} from '@angular/router';
import { SharedService } from '../services/api-integration-files/shared.service';
import { RootService } from '../services/root.service';
@Injectable()
export class AdminGuard implements CanActivate {
  currentRoute: any;
  constructor(
    private router: Router,
    private rootService: RootService,
    private sharedService:SharedService
  ) {}
  canActivate() {
    let isLoggedIn = this.rootService.isUserLoggedIn();
    let role = this.rootService.getUserRole();
    // let role = this.localStorage.getItem('userCode')?.isLoggedIn ? true : false
    if(isLoggedIn && role == 'Admin'){


        let userType = this.rootService.getUserRole();
      let isLoggedIn =  this.rootService.getUserData() ? true : false ;
      let freeaccessToken: any = '';
      let freerefreshToken: any = '';
      if (isLoggedIn && userType === 'Admin') {
        freeaccessToken =  this.sharedService.getStoredGeoTokens('free_access_token');
        freerefreshToken =  this.sharedService.getStoredGeoTokens('free_ref_token');
        if (!(freeaccessToken && freerefreshToken)) {
           this.rootService.getGeoToken();
        }
      }
        return true;
    }
    this.router.navigateByUrl('')
    return false;
    }
  }
