import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { SharedService } from '../api-integration-files/shared.service';
import { SecurestorageService } from '../encryption/securestorage.service';
import { RootService } from '../root.service';
import { BehaviorDataService } from '../api-integration-files/behavior-data.service';
import { Router } from '@angular/router';
import { LoaderService } from '../loader.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  freeaccessToken: any;
  freerefreshToken: any;
  isLoggedIn: boolean = false;


  private FreeTokenInProgress = false;
  private FreeTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  private FreeRefreshTokenInProgress = false;
  private FreeRefreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  constructor(
    private localStorage: SecurestorageService,
    private sharedService: SharedService,
    private rootService: RootService,
    private cookieService: CookieService,
    private behaviorData: BehaviorDataService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  injectToken(request: HttpRequest<any>) {
    const userData = this.rootService.getUserData() ? this.rootService.getUserData() : null;
    const accessToken = this.cookieService.get('accessToken') != undefined && this.cookieService.get('accessToken') != null && this.cookieService.get('accessToken') != '' ? JSON.parse(this.cookieService.get('accessToken')) : undefined;
    if (
      request.url.includes('geo_dropdown') ||
      request.url.includes('FreeSearchTokenRequest') ||
      request.url.includes('refresh')
    ) {
      this.behaviorData.freeSearchTokenUpdated.subscribe((val: any) => {
        this.freeaccessToken = this.cookieService.getObject('free_access_token') ? this.cookieService.getObject('free_access_token') : null;
        this.freerefreshToken = this.cookieService.getObject('free_ref_token') ? this.cookieService.getObject('free_ref_token') : this.localStorage.getItem('free_ref_token');
      });
      if (this.freeaccessToken && this.freerefreshToken) {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.freeaccessToken}`,
            // "Permissions-Policy": "camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*",
            // "Strict-Transport-Security": "max-age=31536000; includeSubdomains",
            // "X-Frame-Options": "SAMEORIGIN",
            // "X-Content-Type-Options": "nosniff",
            // "X-Xss-Protection": "1; mode=block",
            // "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
          },
        });
      }
      else {
        return request;
      }
    } else {
      if (accessToken && userData) {
        return request.clone({
          setHeaders: {
            'user-code': userData.user_code,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        return request;
      }
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(this.injectToken(request)).pipe(
        catchError(this.handleError)
      );
    }
    const accessToken = this.cookieService.get('accessToken');
    const refreshToken = this.cookieService.get('refreshToken');
    const accessExpired :any = this.cookieService.get('free_access_token') ? this.cookieService.get('free_access_token') : null;
    const refreshExpired  :any= this.cookieService.get('free_ref_token') ? this.cookieService.get('free_ref_token')  :  this.localStorage.getItem('free_ref_token');

    if(request.url.includes('geo_dropdown') || request.url.includes('FreeSearchTokenRequest')){
  //       if (accessExpired && refreshExpired) {
  //         return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
  //       }
  //       if (!accessExpired && !refreshExpired) {
  //         if (!this.FreeTokenInProgress) {
  //           this.FreeTokenInProgress = true;
  //           this.FreeTokenSubject.next(null);
  //         } else {
  //           this.isLoggedIn = this.rootService.isUserLoggedIn();
  //           if (this.isLoggedIn) {
  //             if (!this.FreeRefreshTokenInProgress) {
  //               this.FreeRefreshTokenInProgress = true;
  //               this.FreeRefreshTokenSubject.next(null);
  //               if (!accessExpired) {
  //                 if (
  //                   this.localStorage.getItem('FreeRefreshTokenInProgress') != 'true'
  //                 ) {
  //                   this.localStorage.setItem('FreeRefreshTokenInProgress', 'true');
  //                   let res :any = this.rootService.refreshToken2();
  //                   return res.pipe(
  //                     switchMap((authResponse:any) => {
  //                       if (authResponse) {
  //                         this.FreeRefreshTokenInProgress = false;
  //                       }
  //                       this.FreeRefreshTokenSubject.next(
  //                         authResponse.data.refresh_token
  //                       );

  //                       return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
  //                     })
  //                   );
  //                 }
  //               } else {
  //               }
  //             } else {
  //               if (!accessExpired) {
  //                 if (
  //                   this.localStorage.getItem('FreeRefreshTokenInProgress') != 'true'
  //                 ) {
  //                   this.localStorage.setItem('FreeRefreshTokenInProgress', 'true');
  //                   // return
  //                    this.rootService.refreshToken2();
  //                 }
  //               }
  //             }
  //           } else {
  // if (isPlatformBrowser(this.platformId)) {

  //             window.location.reload();
  // }
  //           }
  //         }
  //       }
  //       if (!accessExpired && refreshExpired) {
  //         if (!this.FreeRefreshTokenInProgress) {
  //           this.FreeRefreshTokenInProgress = true;
  //           this.FreeRefreshTokenSubject.next(null);
  //           if (!accessExpired) {
  //             if (this.localStorage.getItem('FreeRefreshTokenInProgress') != 'true') {
  //               this.localStorage.setItem('FreeRefreshTokenInProgress', 'true');
  //               let res :any = this.rootService.refreshToken2();
  //                   return res.pipe(switchMap((authResponse:any) => {
  //                   if (authResponse) {
  //                     this.FreeRefreshTokenInProgress = false;
  //                   }
  //                   this.FreeRefreshTokenSubject.next(authResponse.data.refresh_token);
  //                   return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
  //                 })
  //               );
  //             }
  //           } else {
  //           }
  //         } else {
  //           return this.FreeRefreshTokenSubject.pipe(
  //             filter((result) => result !== null),
  //             take(1),
  //             switchMap((res) => {
  //               return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
  //             })
  //           );
  //         }
  //       }
        if (!accessExpired) {
          if (!this.FreeRefreshTokenInProgress) {
           this.FreeRefreshTokenInProgress = true;
           this.rootService.getFreeSearchToken();
          return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
          }else{
        return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
          }
        }else{
        return next.handle(this.injectToken(request)).pipe(catchError(this.handleError));
        }
  }else{
    if (accessToken && refreshToken) {
      return next.handle(this.injectToken(request)).pipe(
        catchError(this.handleError)
      );
    }
    if (!accessToken && !refreshToken) {
      let isLoggedIn = this.behaviorData.isLoggedIn.value;
      if (isLoggedIn) {
        this.sharedService.logout();
      }
    }
    if (!accessToken && refreshToken) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        return this.rootService.refreshToken().pipe(
          switchMap((authResponse: any) => {
            this.isRefreshing = false;
            this.rootService.setToken(authResponse.data);
            this.refreshTokenSubject.next(authResponse.data.refresh_token);
            return next.handle(this.injectToken(request)).pipe(
              catchError(this.handleError)
            );
          })
        );
      } else {
        return this.refreshTokenSubject.pipe(
          filter((token) => token !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request)).pipe(
              catchError(this.handleError)
            );
          })
        );
      }
    }
    return next.handle(this.injectToken(request)).pipe(
      catchError(this.handleError)
    );
  }
}

  handleError(error: HttpErrorResponse) {
    let data: any = {};
    let errorMsg = 'Something went wrong, Please try again !';
    data = {
      msg:
        error && error.error && error.error.data
          ? error.error.data.msg
          : error && error.error && error.error == '<h1>403 Forbidden</h1>'
          ? errorMsg
          : error,
      status: error.status,
      netWorkStatus: window.navigator.onLine,
    };
    // if (error.status === 401) {
    //     this.localStorage.clear();
    //     throw data;
    // }
    if (error.status === 0 || error.status === 500 ) {
      data.msg = 'Something went wrong, Please try again !';
      return throwError(data);
    }
    if (!data.netWorkStatus) {
      data.msg = 'You are Offline.Please check the network connectivity';
      return throwError(data);
    }
    return throwError(data);
  }
}
