import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurestorageService } from '../services/encryption/securestorage.service';
import { RootService } from '../services/root.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private rootService: RootService,
    private localStorage : SecurestorageService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let userDetails = this.rootService.isUserLoggedIn();
    let userData = this.rootService.getUserData();
    let userType = this.rootService.getUserRole();

    const url: string = state.url;
    if (!userDetails) {

    }
    else{
      if(userData && (url == '/') ){
      if(userType === "Dealer"){
        this.router.navigateByUrl('/dealer-dashboard');
        return true;
    }
    if(userType === "Evaluator" || userType === "Sales Person"){
        this.router.navigateByUrl('/evaluator-dashboard');
        return true;
    }
    if(userType === "Admin"){
      this.router.navigateByUrl('/admin-dashboard');
      return true;
    }
    }

  }

    return true;
  }

}
// if(!this.auth.isLoggedIn()){
//     return true;
//   }
//   this.router.navigateByUrl('/dashboard');
//   return false;
