import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie';
import { SecurestorageService } from '../../shared/services/encryption/securestorage.service';
import { ApiUrlListService } from './api-integration-files/api-url-list.service';
import { DataService } from './api-integration-files/data.service';
import { tap } from 'rxjs/operators';
import { BehaviorDataService } from './api-integration-files/behavior-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RootService {
  showServicecall: boolean = false;
  expiredDate: any;
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'request-id': 'b997a77ae2b3f30c1bed151c3e42434f',
    }),
  };

  constructor(
    private cookieService: CookieService,
    private localStorage: SecurestorageService,
    private apiList: ApiUrlListService,
    private dataService: DataService,
    private behaviorData: BehaviorDataService,
    private http: HttpClient
  ) {}

  upgradeVersion() {
    const APP_VERSION = environment?.version;
    if (
      typeof localStorage.APP_VERSION === 'undefined' ||
      localStorage.APP_VERSION === null
    ) {
      localStorage.setItem('APP_VERSION', APP_VERSION);
    }
    if (localStorage.APP_VERSION != APP_VERSION) {
      localStorage.clear();
      this.deleteCookies();
      localStorage.setItem('APP_VERSION', APP_VERSION);
    }
    return Promise.resolve();
  }

  deleteCookies() {
    this.cookieService.remove('access_token');
    this.cookieService.remove('ref_token');
    this.cookieService.remove('free_access_token');
    this.cookieService.remove('free_ref_token');
    window.location.reload();
  }
  getToken() {
    this.cookieService.getObject('access_token');
    this.cookieService.getObject('ref_token');
  }

  setToken(tokenData: any) {
    var now = new Date();
    var accessTokenExpiry = new Date(
      now.getTime() + tokenData.expires_in * 1000
    );
    var refreshTokenExpiry = new Date(
      now.getTime() + tokenData.refresh_expires_in * 1000
    );
    // ,'httpOnly':true}
    this.cookieService.putObject('accessToken', tokenData.access_token, {
      expires: accessTokenExpiry,
      secure: true,
      sameSite: 'none',
    });
    localStorage.setItem('refreshTokenExpiry',JSON.stringify(refreshTokenExpiry))
    localStorage.setItem('ACTUALrefreshTokenExpiry',JSON.stringify(tokenData.refresh_expires_in))

    this.cookieService.putObject('refreshToken', tokenData.refresh_token, {
      expires: refreshTokenExpiry,
      secure: true,
      sameSite: 'none',
    });
  }

  // setToken(data: any) {
  //   let TodaysDate = new Date();

  //   let accessMinutes = data.expires_in * 1000;
  //   var accessExpiresIn = (accessMinutes - (accessMinutes / 10))
  //   let accessExpiryDate = new Date(TodaysDate.getTime() + accessExpiresIn);

  //   let refreshMinutes = data.refresh_expires_in * 1000;
  //   var refreshExpiresIn = (refreshMinutes - (refreshMinutes / 10))
  //   let refreshExpiryDate = new Date(TodaysDate.getTime() + refreshExpiresIn);

  //   this.cookieService.putObject('access_token', data.access_token, { 'expires': accessExpiryDate });
  //   this.cookieService.putObject('ref_token', data.refresh_token, { 'expires': refreshExpiryDate });
  // }

  setUserData(data: any) {
    this.localStorage.setItem('userData', JSON.stringify(data));
  }

  getUserData() {
    return this.localStorage.getItem('userData')
      ? JSON.parse(this.localStorage.getItem('userData'))
      : null;
  }


  getUserRole(){
    return this.localStorage.getItem('userType')
      ? JSON.parse(this.localStorage.getItem('userType'))
      : null;
  }
  isUserLoggedIn() {
    let isLoggedIn = this.behaviorData.isLoggedIn.value;
    return isLoggedIn ? true : false;
  }

  refreshToken() {
    let refreshToken = this.cookieService.get('refreshToken')
      ? JSON.parse(this.cookieService.get('refreshToken'))
      : '';
    let requestBody = {
      refresh_token: refreshToken,
    };
    let apiUrl: any = this.apiList.returnAPI('refreshToken');
    return this.dataService.post(apiUrl, requestBody).pipe(
      tap((response: any) => {
        if (response.success) {
          this.setToken(response.data);
        }
      })
    );
  }
getFreeRefToken(){
  this.cookieService.getObject('free_ref_token')
  ? this.cookieService.getObject('free_ref_token') : null
}
  refreshToken2(): Promise<any> {
    let refreshToken = this.cookieService.getObject('free_ref_token')
      ? this.cookieService.getObject('free_ref_token')
      : this.localStorage.getItem('free_ref_token');
    let data = {};

    // if (refreshToken) {
    data = {
      refresh_token: refreshToken,
    };
    this.showServicecall = true;
    const promise1 = this.http
      .post(`${environment.freeSearchPort}token/refresh/`, data, this.options)
      .toPromise()
      .then((response: any) => {
        this.showServicecall = false;
        if (response['success'] === true) {
          this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
          this.storeJwtToken(response);
        }
        return;
      })
      .catch((err) => {
        return;
      });
    return promise1;
  }
  getFreeSearchToken(): Promise<any> {
    let user = {
      device_id: '83596698386586760000fdLHEgDYlE',
    };

    this.showServicecall = true;
    const promise = this.http
      .post(
        `${environment.freeSearchPort}FreeSearchTokenRequest/`,
        user,
        this.options
      )
      .toPromise()
      .then((response: any) => {
        if (response) {
          this.showServicecall = false;
          this.expiredDate = new Date();
          let Minutes = response.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
          var expirein = Minutes - Minutes / 10;
          let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
          // ,'httpOnly':true,
          this.cookieService.putObject(
            'free_access_token',
            response.data['access_token'],
            { expires: expiredate2, secure: true, sameSite: 'none'}
          );
          this.cookieService.putObject(
            'free_ref_token',
            response.data['refresh_token'],
            { secure: true, sameSite: 'none'  }
          );
          this.localStorage.setItem(
            'free_ref_token',
            response.data['refresh_token']
          );
          this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
          this.behaviorData.freeSearchTokenUpdated.next(true);

          // this.behaviorService.freeSearchAccessTokenUpdated.next(response.data['access_token']);
          // this.behaviorService.freeSearchRefreshTokenUpdated.next(response.data['refresh_token']);
        }
      });
    return promise;
    // }

    // }
    // catch (error) {
    // }
  }

  getGeoToken(): Promise<any>{
    let requestBody = {
      device_id: '83596698386586760000fdLHEgDYlE',
    };
    const promise = this.http.post(`${environment.freeSearchPort}FreeSearchTokenRequest/`, requestBody,this.options).toPromise().then((response: any) => {
      if(response){
        this.expiredDate = new Date();
          let milliseconds = response.data['expires_in'] * 1000;
          let expiredate2 = new Date(this.expiredDate.getTime() + milliseconds);
          this.cookieService.putObject('free_access_token',response.data['access_token'],{ expires: expiredate2, secure: true, sameSite: 'none' });
          this.cookieService.putObject('free_ref_token',response.data['refresh_token'],{ secure: true, sameSite: 'none' });
          this.localStorage.setItem('free_ref_token',response.data['refresh_token']);
          this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
          this.behaviorData.freeSearchTokenUpdated.next(true);
      }
    })
    return promise;
  }

  setGeoTokens(response: any){
    this.expiredDate = new Date();
    let milliseconds = response.data['expires_in'] * 1000;
    let expiredate2 = new Date(this.expiredDate.getTime() + milliseconds);

    this.cookieService.putObject('free_access_token',response.data['access_token'],{ expires: expiredate2, secure: true, sameSite: 'none' });
    this.cookieService.putObject('free_ref_token',response.data['refresh_token'],{ secure: true, sameSite: 'none' });
    this.localStorage.setItem('free_ref_token',response.data['refresh_token']);
    this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
    this.behaviorData.freeSearchTokenUpdated.next(true);
  }

  storeJwtToken(response: any) {
    if (response['success'] === true) {
      this.expiredDate = new Date();
      let Minutes = response.data['expires_in'] * 1000;
      var expirein = Minutes - Minutes / 10;
      let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
      // ,'httpOnly':true
      this.cookieService.putObject(
        'free_access_token',
        response.data['access_token'],
        { expires: expiredate2, secure: true, sameSite: 'none' }
      );
      this.cookieService.putObject(
        'free_ref_token',
        response.data['refresh_token'],
        { secure: true, sameSite: 'none'}
      );
      this.localStorage.setItem(
        'free_ref_token',
        response.data['refresh_token']
      );
      this.localStorage.setItem('FreeRefreshTokenInProgress', 'false');
      this.behaviorData.freeSearchTokenUpdated.next(true);
    }
  }

  getStoredGeoTokens(tokenName: string){
    let token: any = "";
    if(tokenName === "free_access_token"){
      token = this.cookieService.getObject(tokenName) ? this.cookieService.getObject(tokenName) : null;
    }
    if(tokenName === "free_ref_token"){
      token = this.cookieService.getObject('free_ref_token') ? this.cookieService.getObject('free_ref_token') : localStorage.getItem('free_ref_token');
    }
    return token;
  }
}
