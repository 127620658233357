import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { RootService } from './shared/services/root.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BehaviorDataService } from './shared/services/api-integration-files/behavior-data.service';
import { Router } from '@angular/router';
import { ApiInterceptor } from './shared/services/interceptors/http.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ErrorHandler } from './shared/services/api-integration-files/error-handler.service';
import { APP_BASE_HREF } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './shared/services/loader.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CookieService } from 'ngx-cookie';
import { SharedService } from './shared/services/api-integration-files/shared.service';
import { JoyrideModule } from 'ngx-joyride';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthGuard } from './shared/guard/auth.guard';
import { AdminGuard } from './shared/guard/admin.guard';
import { DealerGuard } from './shared/guard/dealer.guard';
import { EvaluatorGuard } from './shared/guard/evaluator.guard';

export function init_app(
  rootService: RootService,
  behaviorData: BehaviorDataService,
  sharedService: SharedService,
  router: Router
) {
  let userType = rootService.getUserRole();
  if (userType === 'Dealer') {
    behaviorData.isLoggedIn.next(true);
  } else if (userType === 'Evaluator') {
    behaviorData.isLoggedIn.next(true);
  } else if (userType === 'Sales Person') {
    behaviorData.isLoggedIn.next(true);
  } else if (userType === 'Admin') {
    behaviorData.isLoggedIn.next(true);
  } else {
    behaviorData.isLoggedIn.next(false);
    router.navigateByUrl('');
  }

  // let isLoggedIn = rootService.getUserData() ? true : false ;
  // let freeaccessToken: any = '';
  // let freerefreshToken: any = '';
  // if (isLoggedIn && userType === 'Admin') {
  //   freeaccessToken = sharedService.getStoredGeoTokens('free_access_token');
  //   freerefreshToken = sharedService.getStoredGeoTokens('free_ref_token');
  //   if (!(freeaccessToken && freerefreshToken)) {
  //     return () => rootService.getGeoToken();
  //   }
  // }
  return () => rootService.upgradeVersion();
}

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    JoyrideModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CookieModule.forRoot(),
    BrowserAnimationsModule,
    MatSnackBarModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    MatProgressSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [BsModalService,
    AuthGuard,AdminGuard,DealerGuard,EvaluatorGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [RootService, BehaviorDataService, SharedService, Router],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandler, multi: true },
    // { provide: APP_BASE_HREF, useValue: "https://ibpodev.home.tatamotors/RVSFAdmin/" },
    // { provide: APP_BASE_HREF, useValue: "/" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
