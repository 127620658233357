import { Component, OnDestroy, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { JoyrideService } from 'ngx-joyride';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { BehaviorDataService } from './shared/services/api-integration-files/behavior-data.service';
import { SharedService } from './shared/services/api-integration-files/shared.service';
import { RootService } from './shared/services/root.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'TML-RVSF-Admin';
  lastPing: Date;
  idleState: string;
  timedOut = false;
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private sharedService: SharedService,
    private behaviourService: BehaviorDataService,
    private readonly joyrideService: JoyrideService,
    private modalService: BsModalService,
    private rootService :RootService
  ) {}

  ngOnInit(): void {
  //   this.joyrideService.startTour(
  //     { steps: ['username', 'password', 'login'] }
  // );
    this.behaviourService.isLoggedIn
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loginState: boolean) => {
        if (loginState) {
          this.idle.watch();
          this.timedOut = false;

        } else {
          this.idle.stop();
        }
      });
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(15);
    // this.idle.setTimeout(2);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out !!!';
      this.timedOut = true;
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'Idle Started !!!';
      let isLoggedIn = this.behaviourService.isLoggedIn.value;
      if (isLoggedIn) {
        Swal.fire({
          title: 'Session Timeout',
          html: "You have gone Idle, you'll be logged out in 10 seconds.",
          icon: 'error',
          timer: 10000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          customClass: {
            icon: 'pure-red',
            timerProgressBar: 'pure-red-line',
            title: 'title-button',
            cancelButton: 'blue-button',
          },
        }).then((response) => {
          let closeFormat: any = response.dismiss;
          if (closeFormat == 'timer' && response.isDismissed) {
            this.sharedService.logout();
          }
        });
      }
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState =
        "You have gone Idle, you'll be logged out in " +
        countdown +
        ' seconds.';
    });
    // this.idle.watch();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    // this.modalService.hide()
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

}
