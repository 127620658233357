import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import CryptoJS from "crypto-js";
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
var SECRET_KEY = "chair@rewire$9876";


@Injectable({
  providedIn: 'root'
})
export class SecurestorageService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) { }


  setItem(key: any, value: any) {
    if (isPlatformBrowser(this.platformId)) {
      let valuenew = CryptoJS.AES.encrypt(value, SECRET_KEY);
      localStorage.setItem(key, valuenew.toString());
      return null;
    } else {
      return null;
    }
  }

  removeItem(key: any){
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
      return null;
    }
    else{
      return null;
    }
  }

  encryptData(data: any) {

    var skey = environment.AES_ACCESS_KEY
    var salt = environment.AES_ACCESS_IV
    var iv = CryptoJS.enc.Utf8.parse(salt);
    var key = CryptoJS.enc.Utf8.parse(skey);
    var cipherpassword = CryptoJS.AES.encrypt(data, key, { iv: iv }).toString();
    return cipherpassword;
  }

  getItem(key: any) {
    if (isPlatformBrowser(this.platformId)) {
      let valuenew = localStorage.getItem(key);
      if (valuenew) {
        let data: any = CryptoJS.AES.decrypt(valuenew, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  clear() {
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigateByUrl("");
      localStorage.clear();
      window.location.reload();
    } else {
      // this.router.navigateByUrl("");
    }
  }
}
