import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  multiformDataHeaders= new HttpHeaders().set('Content-Type', 'multipart/form-data');

  constructor(private http: HttpClient) {}

  public post<T>(apiUrl: string, requestBody: any): Observable<T> {
    return this.http
    .post<T>(apiUrl, requestBody)
    .pipe(catchError(this.handlError));
}
async postAsync(apiUrl: string, requestBody:any): Promise<any> {
  return await this.http.post<any>(apiUrl, requestBody).pipe(catchError(this.handlError)).toPromise();
}

public get<T>(apiUrl: string, params: any): Observable<T> {
    return this.http
      .get<T>(apiUrl, { params })
      .pipe(catchError(this.handlError));
  }

  async getGeoLocation(data:any) {
    return  await this.http.post(`${environment.locationPort}`, data)
      .toPromise().then(res => {  return res; });
  }

  public getById<T>(id: any) {
    return this.http.get<T>(id).pipe(catchError(this.handlError));
  }

  public uploadFiles<T>(apiUrl: string, formData: FormData): Observable<T> {
    return this.http.post<T>(apiUrl, formData, {
      reportProgress: true,
      responseType: 'json',

    });
  }
  handlError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
