
<!-- <app-loader></app-loader> -->
<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p> -->
  <ngx-spinner
bdColor = "rgba(0, 0, 0, 0.4)" size = "medium" color = "#fff" type = "ball-pulse"
[fullScreen] = "true">
<div class="la-ball-pulse la-2x">
  <div></div>
  <div></div>
  <div></div>
</div>
  </ngx-spinner>
<router-outlet></router-outlet>
