import {  Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  Event as NavigationEvent,
} from '@angular/router';
import { RootService } from '../services/root.service';
@Injectable()
export class EvaluatorGuard implements CanActivate {
  currentRoute: any;
  constructor(
    private router: Router,
    private rootService: RootService,
  ) {}
  canActivate() {
    let isLoggedIn = this.rootService.isUserLoggedIn();
    let role = this.rootService.getUserRole();
    // let role = this.localStorage.getItem('userCode')?.isLoggedIn ? true : false
    if(isLoggedIn && (role == 'Evaluator' || role == 'Sales Person')){
        return true;
    }
    this.router.navigateByUrl('')
    return false;
    }
  }
