import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoading = new BehaviorSubject<boolean>(false);
  // isLoading = new Subject<boolean>();
  // public readonly loading$ = this.isLoading.asObservable();
  constructor() { }

  show() {
    this.isLoading.next(true);
    // setTimeout(() => {
    //   this.hide();
    // }, 0);
 }

 hide() {
    this.isLoading.next(false);
 }
}
