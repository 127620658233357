import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlListService {

  constructor() { }

  apiUrlList: any[] = [
    {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/refresh_token/', id: 'refreshToken'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'account/change_password/', id: 'changePassword'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/send_otp/', id: 'sendOTP'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/reset_password/', id: 'resetPassword'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/validate/', id: 'emailVerification'},

    {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/admin_login/', id: 'login'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/dashboard/', id: 'dashboard'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_user_vehicle_list/', id: 'vehicleList'}, //Explore and buy vehicle list
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_user_list/', id: 'evaluatorList'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_vehicle_master/', id: 'get_vehicle_master'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/update_bulk_vehicle_csv/', id: 'update_bulk_vehicle_csv'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_update_price_tool/', id: 'get_update_price_tool'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/add_user/', id: 'addEvaluator'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/assign_evaluator/', id: 'assignEvaluator'}, //Assign Evaluator popup
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/dropdown/', id: 'evaluatorDropdown'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/vehicle_request_status/', id: 'requestStatus'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/upload_files/', id: 'uploadFiles'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/vehicle_pricing_tool/', id: 'priceCalculation'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_user_vehicle_details/', id: 'vehicleDetails'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_deals_count/', id: 'dealsCount'},
     {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_user_vehicle_details/', id: 'urnDetail'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/update_final_price/', id: 'finalPrice'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_document_master/', id: 'documentMaster'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/update_details/', id: 'updateSeller'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/dealer_location_list/', id: 'dealerList'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/add_dealer_location/', id: 'addDealer'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/update_vehicle_variant/', id: 'updateVariant'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'account/change_password/', id: 'changePassword'},


    {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/dropdown/', id: 'dropdown'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'deal/insert_vehicle_address/', id: 'addAddress'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'deal/add_bank_details/', id: 'addBankDetails'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'deal/schedule_meeting/', id: 'scheduleMeet'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'deal/calculate_price_range/', id: 'dealPrice'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'account/logout/', id: 'logout'},

    {apiUrl: environment.httpPort + environment.apiVersion + 'no_auth/vehicle_mapping_dropdown/', id: 'vehicle_dropdown'},

    //get all user otps
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_user_otp_list/', id: 'getUserOTP'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/get_update_price_tool/', id: 'get_update_price_tool'},
    {apiUrl: environment.httpPort + environment.apiVersion + 'admin/add_vehicle_remark/', id: 'add_vehicle_remark'},


  ]

  returnAPI(id: String) {
    let fetchedAPI = this.apiUrlList.find(x => x.id === id);
    return fetchedAPI.apiUrl;
  }

}
