import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviorDataService {

  public isLoggedIn = new BehaviorSubject<boolean>(false);
  public isFilterChanged = new BehaviorSubject<boolean>(false);
  public freeSearchTokenUpdated = new BehaviorSubject<any>(null);
  public isdashboardFilter = new BehaviorSubject<boolean>(false);
  public usersSubject = new BehaviorSubject<any>(null);
  public isLoginTypes = new BehaviorSubject<boolean>(false);

  public certificateOfScrapUploaded = new BehaviorSubject<boolean>(true);
  private logisticCost: string = '';
  // isCertificateOfScrapUploaded: boolean = false;



  constructor() {
    this.isLoggedIn = new BehaviorSubject<boolean>(false);
    this.isFilterChanged = new BehaviorSubject<boolean>(false);
    this.freeSearchTokenUpdated = new BehaviorSubject<any>(null);
    this.isdashboardFilter = new BehaviorSubject<boolean>(false);
    this.usersSubject = new BehaviorSubject<any>(null);
    this.isLoginTypes = new BehaviorSubject<boolean>(false);

   }



}
