import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'login'
  // },
  // {
  //   path: 'login',
  //   loadChildren: () => import("./login/login.module").then(l => l.LoginModule)
  // },
  {
    path: '',
    loadChildren: () => import("./layout/layout.module").then(m => m.LayoutModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: "enabled",
    scrollPositionRestoration: "enabled",
    scrollOffset: [0, 0],
    onSameUrlNavigation: "reload",
    initialNavigation: "enabled"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
