import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, Injector, PLATFORM_ID } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { SecurestorageService } from "../encryption/securestorage.service";
import { RootService } from "../root.service";
import { BehaviorDataService } from "./behavior-data.service";

@Injectable({
  providedIn: 'root'
})

export class SharedService {

  userdetails: any;
  usertoken: any;
  user_code: any;
  weekDays: any[] = [
    {
      day: 'Sunday', id: 0,
    },
    {
      day: 'Monday', id: 1,
    },
    {
      day: 'Tuesday', id: 2,
    },
    {
      day: 'Wednesday', id: 3,
    },
    {
      day: 'Thursday', id: 4,
    },
    {
      day: 'Friday', id: 5,
    },
    {
      day: 'Saturday', id: 6
    }
  ]
  selectedURN: any;
  public loginStatus: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private behaviorData: BehaviorDataService,
    @Inject(Injector) private readonly injector: Injector) { }

    private get toastr() {
      return this.injector.get(ToastrService);
    }

    setToken(tokenData: any){
      var now = new Date();
      var accessTokenExpiry = new Date(now.getTime() + tokenData.expires_in*1000);
      var refreshTokenExpiry = new Date(now.getTime() + tokenData.refresh_expires_in*1000);
      // ,'httpOnly':true
      localStorage.setItem('refreshTokenExpiryLOGIN',JSON.stringify(refreshTokenExpiry))
      localStorage.setItem('ACTUALrefreshTokenExpiryLOGIN',JSON.stringify(tokenData.refresh_expires_in))

      this.cookieService.putObject("accessToken",tokenData.access_token,{expires: accessTokenExpiry,'secure':true ,sameSite :'none'});
      this.cookieService.putObject("refreshToken",tokenData.refresh_token,{expires: refreshTokenExpiry,'secure':true ,sameSite :'none'});
    }

    getStoredGeoTokens(tokenName: string){
      let token: any = "";
      if(tokenName === "free_access_token"){
        token = this.cookieService.getObject(tokenName) ? this.cookieService.getObject(tokenName) : null;
      }
      if(tokenName === "free_ref_token"){
        token = this.cookieService.getObject('free_ref_token') ? this.cookieService.getObject('free_ref_token') : localStorage.getItem('free_ref_token');
      }
      return token;
    }

  failedSnackBarResponse(msg: any) {
    this.toastr.error(msg);
    // this.snackBar.open(msg, '', {
    //   duration: 5000,
    //   panelClass: ["custom-styleRed"], verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
    //   horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    // });
  }

  successSnackBarResponse(msg: any) {
    this.toastr.success(msg);
    // this.snackBar.open(msg, '', {
    //   duration: 5000,
    //   panelClass: ["custom-style"], verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
    //   horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    // });
  }

  showSuccess(message: any, title: any) {
    this.toastr.success(message, title)
  }

  showError(message: any, title: any) {
    this.toastr.error(message, title)
  }

  logout() {

    localStorage.removeItem("userData");
    localStorage.removeItem("userType");
    localStorage.removeItem("userCode");
    localStorage.removeItem("filterData");
    localStorage.removeItem('isUserDeactivated')
    localStorage.removeItem('FreeRefreshTokenInProgress')
    localStorage.removeItem('free_ref_token')
    localStorage.removeItem("part_type");

    this.cookieService.removeAll();
    this.behaviorData.isLoggedIn.next(false);
    this.successSnackBarResponse("Successfully Logged Out");
    this.router.navigateByUrl("/");
  }

  getDayByEnumValues(enumValue: any){
    let selectedDate = this.weekDays.find(x => x.id === enumValue);
    return selectedDate?.day;
  }
}
