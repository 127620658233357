import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandler implements HttpInterceptor {
  constructor(private router: Router,
    private toastr : ToastrService) {}

    errorMsg = 'Something went wrong, Please try again !';
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(catchError((error) => this.handleError(error, req)));
  }

  handleError(error: HttpErrorResponse, request: HttpRequest<HttpEvent<any>>) : Observable<HttpEvent<any>>{
    if(error.status === 400){
      // this.toastr.error(this.errorMsg);

    }
    else if(error.status === 401){
      // this.toastr.error(this.errorMsg);

    }
    else if(error.status === 403){
      // this.toastr.error(this.errorMsg);

    }
    else if(error.status === 404){
      // this.toastr.error(this.errorMsg);

    }
    else if(error.status === 412){
      // this.toastr.error(this.errorMsg);
    }
    else if(error.status === 500){
      // this.toastr.error(this.errorMsg);

    }
    else if(error.status == 0){
      this.toastr.error(this.errorMsg);

    }
    throw error;
  }
}
